.blank-state{
    margin: auto;
    text-align: center;
    padding-top: 50px;
}

.blank-state ion-img{
    max-width: 100px;
    margin: auto;
}
