#project-modal{
.color-black{
  width: 16px;
  height: 16px;
  background-color: #000;
}

select:before{
  color: red;
}

  .theme-red{
    color: red;
  }
}
