.nes-dialog {
  margin-block-start: 0;
  padding-inline-start: 0;

  .nes-btn{
    margin: 20px 20px 20px 0;
  }

  .dialog-menu{
    text-align: center;
  }
}
