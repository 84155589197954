@import "src/theme/variables";

.battle-award-item {

  .img-area{
    height: 64px;
    img{
      max-height: 62px;
      max-width: 62px;
    }
  }
  .Character, img, ion-icon{
    margin: auto;
    display: block;
  }
  ion-icon{
    font-size: 2.5rem
  }
  p {
    font-size: 8px;
    margin-bottom: 0;
    text-align: center;
  }
}


