/*
 * Red Colors
 * -------------------------------------------
 */
.green-theme{
  --ion-color-tertiary: #55917F;
  --ion-color-tertiary-rgb: 85,145,127;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4b8070;
  --ion-color-tertiary-tint: #669c8c;
}

.ios .green-theme {
  --ion-background-color: #7dc8aa;

}

.md .green-theme {
  --ion-background-color: #7dc8aa;
}

.green-theme ion-toolbar {
  --background: var(--ion-background-color);
}

.green-theme ion-header {
  background: var(--ion-background-color);
}
