@import "src/theme/variables";

.level-up-modal {

  .level-up-details{
  text-align: center;

    .level-up-list {
      .level-up-item {
        margin: auto;
        max-width: 210px;
      }
    }
  }

}
