/*
The comments here attempt to explain the implementations of spritesheet animations in Danger Crew.

We'll utilize a dynamic "pixel size" which will allow us to upscale the pixel art in even multiplications.
EX: if an asset is designed as a 10px by 10px square, we can multiply by pixel size of 4 to enlarge the asset to 40px by 40px. This value can be adjusted per screen real estate.
*/

:root {
  --pixel-size: 2; /* Try playing with this value! */
}


/*
   Allow pixel art images and canvas tags to scale up without losing quality
*/
.PixelArtImage {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

/*
	This is the square crop of the character, each frame designed at natural size 32x32.
	Multiplied by dynamic pixel size.
*/
.Character {
  width: calc(var(--pixel-size) * 32px);
  height: calc(var(--pixel-size) * 32px);
  overflow: hidden;
  position: relative;
  //margin: calc(var(--pixel-size) * 6px) auto;
}

.Character_shadow {
  width: calc(var(--pixel-size) * 32px);
  height: calc(var(--pixel-size) * 32px);
  opacity: 0.25;
  position: absolute;
}

/* Set up a CSS Animation to move our spritesheet to the LEFT. */
@keyframes walkAnimation {
  from {
    transform: translate3d(0%, 0%, 0);
  }
  to {
    transform: translate3d(-100%, 0%, 0);
  }
}

/*
	One spritesheet containing layout of 4 by 4 frames, each designed at 32x32.
	Total width = 128px multiplied by dynamic pixel size.
	The visible part is cropped out via .Character's overflow:hidden rule. This image lives *inside* the .Character container.
*/
.Character_sprite-sheet {
  width: calc(var(--pixel-size) * 128px);
  position: absolute;
  top: 0;
  left: 0;
}

.Character_sprite-sheet.active {
  display: block;
  animation: walkAnimation 0.6s steps(4) infinite;
  /* ^ "Complete the animation in 4 distinct steps". We use 4 because each row of the spritesheet has 4 frames. */
}

/*
	Set up classes that change the y position of underlying spritesheet.
	Spritesheet is designed with this layout:
		D1 D2 D3 D4
		R1 R2 R3 R4 (32px away from top)
		U1 U2 U3 U4 (64px away from top)
		L1 L2 L3 L4 (96px away from top)

   EX: https://s3-us-west-2.amazonaws.com/s.cdpn.io/21542/WalkingDemo-HANK-2-SHEET.png
*/

.Character--walk-down .Character_sprite-sheet {
  top: 0;
}

.Character--walk-left .Character_sprite-sheet {
  top: calc(var(--pixel-size) * -32px);
}

.Character--walk-right .Character_sprite-sheet {
  top: calc(var(--pixel-size) * -64px);
}

.Character--walk-up .Character_sprite-sheet {
  top: calc(var(--pixel-size) * -96px);
}


.Character {
  &.level-up-animation {
    filter: brightness(1.5);
  }
}

.grass-wild-pokemon {
  width: calc(var(--pixel-size) * 16px);
  margin: 0 auto;
  position: absolute;
  bottom: calc(var(--pixel-size) * -1px);
  left: calc(var(--pixel-size) * 8px);
}

.just-seen-pokedex img{
  filter: grayscale(200%) contrast(0%);
}

