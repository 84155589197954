@import "src/theme/variables";

#battle-page {
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  .battle-area {
    width: 100%;
    margin: 0 auto !important;
    background: url("../../assets/images/battle/background/grass1.png") repeat;
    height: 100%;
    .battle-result{
      text-align: center;
      position: fixed;
      width: 300px;
      top: 175px;
      left: 50%;
      margin-left: -150px;
      z-index: 99;
    }
    .trainer-player-1 {
      margin: 0 auto;

      div {
        //display: inline-table;
        //position: relative;
      }

      .Character {
        margin: 0 auto;
      }

      .nes-balloon {
        //position: fixed;
        //right: 43%;
        display: block;
        //margin-top: -284px;
        zoom: 0.5;

        &.balloon-actions{
          min-height: 192px;
        }

        button {
          zoom: 2
        }

        &.inverse {
          &.from-right::before {
            left: 14rem;
            top: -14px;
          }

          &.from-right::after {
            top: -18px;
            left: 14rem;
            box-shadow: -4px 0, 4px 0, 4px -4px #fff, 0 -4px, 8px -4px, 4px -8px, 8px -8px;
          }
        }
      }
    }

    .trainer-player-2 {
      .Character {
        margin: 0 auto;
      }
    }

  }

  background-color: #EEEEEE;

  .battle-arena {
    position: relative;
    padding: 10px;
    width: 100%;
    height: 350px;
    max-width: 350px;
    margin: 0 auto !important;
    @media only screen and (max-height: 480px) {
      height: 230px;
    }
  }

  .player1-pkmn {
    position: absolute;
    bottom: 50px;

    &.pos-0 {
      left: 0;
    }

    &.pos-1 {
      left: 40%;
    }

    &.pos-2 {
      right: 0;
    }

    &.active-action {
      .Character {
        border-bottom: 5px solid rgba(56, 128, 255, 0.6);
      }
    }


    &.animation-atk-player1 {
      top: 65px;
      bottom: 170px;

      &.animation-atk-gigantamax {
        top: 106px;
      }

      &.enemy-pos-0 {
        left: 0;
      }

      &.enemy-pos-1 {
        left: 40%;

      }

      &.enemy-pos-2 {
        right: 0;
        left: auto;
      }
    }

    .animation-move-name{
      position: absolute;
      left: -44px;
      width: 150px;
      font-size: 0.6rem;
      text-align: center;
    }

    .animation-move-damage{
      position: absolute;
      left: 74px;
      top: -40px;
      color: var(--ion-color-medium);
      font-size: 0.45rem;
    }

    .animation-move-effectiveness{
      position: absolute;
      left: -64px;
      width: 200px;
      text-align: center;
      top: -32px;
      font-size: 0.6rem;
    }
  }

  .player1-hp {
    position: absolute;
    bottom: 20px;

    &.pos-0 {
      left: 0;
    }

    &.pos-1 {
      left: 40%;
    }

    &.pos-2 {
      right: 0;
    }
  }

  .player1-action {
    position: absolute;
    bottom: 10px;
    //background-color: red;
    padding: 10px;
    width: 50px;
    height: 50px;
    --pixel-size: 1; /* Try playing with this value! */


    &.pos-0 {
      left: 0;
    }

    &.pos-1 {
      left: 40%;
    }

    &.pos-2 {
      right: 0;
    }

    &.active-action {
      zoom: 2;
    }
  }

  .player2-pkmn {
    position: absolute;
    top: 10px;

    &.pos-0 {
      left: 0;
    }

    &.pos-1 {
      left: 40%;
    }

    &.pos-2 {
      left: 80%;
    }

    &.animation-atk-player2 {
      top: 165px;
      bottom: 170px;

      &.animation-atk-gigantamax {
        top: 58px;
      }

      &.enemy-pos-0 {
        left: 0;

        &.animation-atk-gigantamax {
          left: -64px;
        }
      }

      &.enemy-pos-1 {
        left: 40%;

        &.animation-atk-gigantamax {
          left: 23%;
        }
      }

      &.enemy-pos-2 {
        right: 0;
        left: auto !important;

        &.animation-atk-gigantamax {
          right: -64px !important;
        }
      }
    }


    .animation-move-name{
      position: absolute;
      /* left: -44px; */
      width: 100%;
      font-size: 0.6rem;
      text-align: center;
      top: -8px;
      margin: auto;
    }

    .animation-move-damage{
      position: absolute;
      left: 74px;
      bottom: -124px;
      color: var(--ion-color-medium);
      font-size: 0.45rem;
    }

    .animation-move-effectiveness{
      position: absolute;
      left: -64px;
      width: 200px;
      text-align: center;
      bottom: -94px;
      font-size: 0.6rem;
    }
  }


  .player2-hp {
    position: absolute;
    top: 10px;

    &.pos-0 {
      left: 0;
    }

    &.pos-1 {
      left: 40%;
    }

    &.pos-2 {
      right: 0;
    }
  }

  .gigantamax {
    --pixel-size: 6; /* Try playing with this value! */
    top: -64px;

    &.player2-pkmn {
      &.pos-1 {
        left: 23%;
      }
    }
  }


  .type-btn{
    &.nes-btn {
      width: 40%;
      height: 56px;
      font-size: 0.6rem;
      display: inline;
      margin: 0 14px;
      &::after{
        box-shadow: inset 0px 0px #006bb3;
      }
      &.normal {
        color: #fff;
        background-color: #aa9
      }

      &.fighting {
        color: #fff;
        background-color: #C46E61;
      }


      &.flying {
        color: #fff;
        background-color:#89f;
      }

      &.poison {
        color: #fff;
        background-color:#a59;
      }

      &.ground {
        color: #fff;
        background-color:#db5;
      }

      &.rock {
        color: #fff;
        background-color:#ba6;
      }

      &.bug {
        color: #fff;
        background-color:#ab2;
      }

      &.ghost {
        color: #fff;
        background-color:#66b;
      }

      &.steel {
        color: #fff;
        background-color:#aab;
      }

      &.fire {
        color: #fff;
        background-color:#f42;
      }

      &.water {
        color: #fff;
        background-color:#39f;
      }

      &.grass {
        color: #fff;
        background-color:#7c5;
      }

      &.electric {
        color: #fff;
        background-color:#fc3;
      }

      &.psychic {
        color: #fff;
        background-color:#f59;
      }

      &.ice {
        color: #fff;
        background-color:#6cf;
      }

      &.dragon {
        color: #fff;
        background-color:#76e;
      }

      &.dark {
        color: #fff;
        background-color:#754;
      }

      &.fairy {
        color: #fff;
        background-color:#e9e;
      }

      &.unknown {
      }

      &.shadow {
      }
    }
  }



}


