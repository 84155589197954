@import 'theme/dark-theme.scss';
@import 'theme/red-theme.scss';
@import 'theme/blue-theme.scss';
@import 'theme/green-theme.scss';


img {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.transparent-header {
  &.header-md:after {
    background-image: none;
  }
}

.invisible-button-fix-form{
  background: transparent;
  padding: 0;
}

.btn-fill-clear{
  background: transparent;
  padding: 0;
  border: 0px;
  color: #209cee;
}

.nes-field{
  padding-bottom: 1.5rem;

  .error{
    font-size: 12px;
    color: #ce372b;
    padding-top: 4px;
  }
}

/*Quick and dirty fix to the date picker issue that was not working with ionic modals*/
.MuiDialog-root{
  z-index: 99999 !important;
}

.item .sc-ion-label-md-h, .item .sc-ion-label-ios-h {
  white-space: normal;
}

ion-title{
  &.title-default{
    font-size: 14px;
    padding-inline-start: 0;
  }
}

.theme-red{
  color: #ce372b;
}

.theme-blue{
  color: #108de0;
}

.theme-yellow{
  color: #f2c409;
}

.theme-gold{
  color: #9E8C55;
}

.theme-silver{
  color: #878A90;
}

.theme-crystal{
  color: #7C8EC4;
}

.theme-ruby{
  color: #790C01;
}

.theme-sapphire{
  color: #1041CC;
}

.theme-emerald{
  color: #079867;
}

.theme-diamond{
  color: #8EF7E4;
}

.theme-pearl{
  color: #91699F;
}

.theme-platinum{
  color: #706B81;
}

.theme-black{
  color: #000;
}

.theme-white{
  color: #FFFDFE;
}

.theme-x{
  color: #7E96C4;
}

.theme-y{
  color: #EC7974;
}

.line-to-pokemon{
  opacity: 0.5;
}

