@import "src/theme/variables";

#battle-awards-page {
  h3{
    font-size: 0.8rem;
    color: var(--ion-color-medium);
  }
}


